import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../helpers/api/apiEndPoint';

const api = new APICore();

function getSkillEndPoint() {
    return api.get(`${URL.GET_SKILL}search=`);
}

function signupAccountEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.SIGNUP_ACCOUNT, data);
}

function getItaaEndPoint(itaa) {
    return api.get(`${URL.GET_ITAA}itaa=${itaa}`);
}

function getIndustoryEndPoint(page, limit) {
    return api.get(`${URL.GET_INDUSTORY}page=${1}&limit=${30}`);
}

function getLanguagesApiEndPoint(data): any {
    return api.get(URL.GET_LANGUAGES, data);
}


export {
    getSkillEndPoint,
    signupAccountEndPoint,
    getItaaEndPoint,
    getIndustoryEndPoint,
    getLanguagesApiEndPoint
};

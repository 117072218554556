import { DashboardActionTypes } from "./constants";

export const getAccountantClient = (data): AuthAction => ({
    type: DashboardActionTypes.GET_ACCOUNTANT_DASHBOARD_FIRST,
    payload: data,

});
export const getClientInvitationList = (data): AuthAction => ({
    type: DashboardActionTypes.GET_CLIENT_INVITATION_LIST_DASHBOARD_FIRST,
    payload: data,

});
export const acceptInvitation = (data): AuthAction => ({
    type: DashboardActionTypes.ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_FIRST,
    payload: data,

});

export const getApiTokenOfDocusign = (data) => ({
    type: DashboardActionTypes.GET_FIRST_DOCUSIGN_API_KEY,
    payload: data,
});

export const getContractPageDate = (data) => ({
    type: DashboardActionTypes.GET_FIRST_CONTRACT_LIST,
    payload: data,
});
export const getClientContract = (data) => ({
    type: DashboardActionTypes.GET_FIRST_CLIENT_CONTRACT_LIST,
    payload: data,
});

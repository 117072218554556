import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { VerifyDetailActionTypes } from './constants';
import {
    getCompanyNameEndPoint,
    registerUserEndPoint,
    companyCheckVerifyEndPoint,
    companyCreateEndPoint
} from './api';


function* getCompanyNameFunction(data) {
    try {
        yield put({
            type: VerifyDetailActionTypes.GET_COMPANY_NAME_LOADING,
            payload: {},
        });
        const response = yield call(getCompanyNameEndPoint, data);

        if (response.data.status) {
            yield put({
                type: VerifyDetailActionTypes.GET_COMPANY_NAME_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: VerifyDetailActionTypes.GET_COMPANY_NAME_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: VerifyDetailActionTypes.GET_COMPANY_NAME_ERROR,
            payload: error,
        });
    }
}


function* registerUserFunction(data) {
    try {
        yield put({
            type: VerifyDetailActionTypes.REGISTER_USER_LOADING,
            payload: {},
        });
        const response = yield call(registerUserEndPoint, data);
        if (response.data.status) {
            yield put({
                type: VerifyDetailActionTypes.REGISTER_USER_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: VerifyDetailActionTypes.REGISTER_USER_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: VerifyDetailActionTypes.REGISTER_USER_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: VerifyDetailActionTypes.REGISTER_USER_ERROR,
            payload: error?.data,
        });
        yield put({
            type: VerifyDetailActionTypes.REGISTER_USER_RESET,
            payload: {},
        });
    }
}

function* companyCheckVerifyFunction(data) {
    try {
        yield put({
            type: VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_LOADING,
            payload: {},
        });
        const response = yield call(companyCheckVerifyEndPoint, data);
        if (response.data.status) {
            yield put({
                type: VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_ERROR,
            payload: error?.data,
        });
        yield put({
            type: VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_RESET,
            payload: {},
        });
    }
}


function* companyCreateFunction(data) {
    try {
        yield put({
            type: VerifyDetailActionTypes.COMPANY_CREATE_LOADING,
            payload: {},
        });
        const response = yield call(companyCreateEndPoint, data);
        if (response.data.status) {
            yield put({
                type: VerifyDetailActionTypes.COMPANY_CREATE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: VerifyDetailActionTypes.COMPANY_CREATE_RESET,
                payload: {},
            });

        } else {
            yield put({
                type: VerifyDetailActionTypes.COMPANY_CREATE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: VerifyDetailActionTypes.COMPANY_CREATE_ERROR,
            payload: error?.data,
        });
        yield put({
            type: VerifyDetailActionTypes.COMPANY_CREATE_RESET,
            payload: {},
        });
    }
}

export function* acctionGetCompanyName(): any {
    yield takeEvery(VerifyDetailActionTypes.GET_COMPANY_NAME_FIRST, getCompanyNameFunction);
}

export function* acctionRegisterUser(): any {
    yield takeEvery(VerifyDetailActionTypes.REGISTER_USER_FIRST, registerUserFunction);
}

export function* acctionCompanyCheckVerify(): any {
    yield takeEvery(VerifyDetailActionTypes.COMPANY_CHECK_VERIFY_FIRST, companyCheckVerifyFunction);
}

export function* acctionCompanyCreate(): any {
    yield takeEvery(VerifyDetailActionTypes.COMPANY_CREATE_FIRST, companyCreateFunction);
}

function* verifyDetailSaga(): any {
    yield all([
        fork(acctionGetCompanyName),
        fork(acctionRegisterUser),
        fork(acctionCompanyCheckVerify),
        fork(acctionCompanyCreate)
    ]);
}

export default verifyDetailSaga;

// @flow
import { APICore } from './apiCore';
import * as URL from '../../helpers/api/apiEndPoint';


const api = new APICore();

// account
// function login(params: any): any {
//     const baseUrl = '/login/';
//     return api.create(`${baseUrl}`, params);
// }

function login(params: any): any {
    const{payload}=params
    return api.create(URL.USER_LOGIN, payload);
}

function logout(): any {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: any): any {
    const baseUrl = '/user/registerUser';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: any): any {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm };

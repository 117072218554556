export const AccountantSearchActionTypes = {
    GET_SEARCH_ACCOUNTANT_LIST_FIRST: '@@auth/GET_SEARCH_ACCOUNTANT_LIST_FIRST',
    GET_SEARCH_ACCOUNTANT_LIST_LOADING: '@@auth/GET_SEARCH_ACCOUNTANT_LIST_LOADING',
    GET_SEARCH_ACCOUNTANT_LIST_SUCCESS: '@@auth/GET_SEARCH_ACCOUNTANT_LIST_SUCCESS',
    GET_SEARCH_ACCOUNTANT_LIST_ERROR: '@@auth/GET_SEARCH_ACCOUNTANT_LIST_ERROR',
    GET_SEARCH_ACCOUNTANT_LIST_RESET: '@@auth/GET_SEARCH_ACCOUNTANT_LIST_RESET',

    GET_MEETING_STATUS_DETAILS_LIST_FIRST: '@@auth/GET_MEETING_STATUS_DETAILS_LIST_FIRST',
    GET_MEETING_STATUS_DETAILS_LIST_LOADING: '@@auth/GET_MEETING_STATUS_DETAILS_LIST_LOADING',
    GET_MEETING_STATUS_DETAILS_LIST_SUCCESS: '@@auth/GET_MEETING_STATUS_DETAILS_LIST_SUCCESS',
    GET_MEETING_STATUS_DETAILS_LIST_ERROR: '@@auth/GET_MEETING_STATUS_DETAILS_LIST_ERROR',
    GET_MEETING_STATUS_DETAILS_LIST_RESET: '@@auth/GET_MEETING_STATUS_DETAILS_LIST_RESET',

    IGNORE_CLIENT_REQUEST_FIRST: '@@auth/IGNORE_CLIENT_REQUEST_FIRST',
    IGNORE_CLIENT_REQUEST_LOADING: '@@auth/IGNORE_CLIENT_REQUEST_LOADING',
    IGNORE_CLIENT_REQUEST_SUCCCESS: '@@auth/IGNORE_CLIENT_REQUEST_SUCCCESS',
    IGNORE_CLIENT_REQUEST_ERROR: '@@auth/IGNORE_CLIENT_REQUEST_ERROR',
    IGNORE_CLIENT_REQUEST_RESET: '@@auth/IGNORE_CLIENT_REQUEST_RESET'


};

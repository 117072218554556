export const VerifyDetailActionTypes = {
    GET_COMPANY_NAME_FIRST: '@@auth/GET_COMPANY_NAME_FIRST',
    GET_COMPANY_NAME_LOADING: '@@auth/GET_COMPANY_NAME_LOADING',
    GET_COMPANY_NAME_SUCCESS: '@@auth/GET_COMPANY_NAME_SUCCESS',
    GET_COMPANY_NAME_ERROR: '@@auth/GET_COMPANY_NAME_ERROR',
    GET_COMPANY_NAME_RESET: '@@auth/GET_COMPANY_NAME_RESET',

    REGISTER_USER_FIRST: '@@auth/REGISTER_USER_FIRST',
    REGISTER_USER_LOADING: '@@auth/REGISTER_USER_LOADING',
    REGISTER_USER_SUCCESS: '@@auth/REGISTER_USER_SUCCESS',
    REGISTER_USER_ERROR: '@@auth/REGISTER_USER_ERROR',
    REGISTER_USER_RESET: '@@auth/REGISTER_USER_RESET',

    COMPANY_CHECK_VERIFY_FIRST: '@@auth/COMPANY_CHECK_VERIFY_FIRST',
    COMPANY_CHECK_VERIFY_LOADING: '@@auth/COMPANY_CHECK_VERIFY_LOADING',
    COMPANY_CHECK_VERIFY_SUCCESS: '@@auth/COMPANY_CHECK_VERIFY_SUCCESS',
    COMPANY_CHECK_VERIFY_ERROR: '@@auth/COMPANY_CHECK_VERIFY_ERROR',
    COMPANY_CHECK_VERIFY_RESET: '@@auth/COMPANY_CHECK_VERIFY_RESET',

    COMPANY_CREATE_FIRST: '@@auth/COMPANY_CREATE_FIRST',
    COMPANY_CREATE_LOADING: '@@auth/COMPANY_CREATE_LOADING',
    COMPANY_CREATE_SUCCESS: '@@auth/COMPANY_CREATE_SUCCESS',
    COMPANY_CREATE_ERROR: '@@auth/COMPANY_CREATE_ERROR',
    COMPANY_CREATE_RESET: '@@auth/COMPANY_CREATE_RESET',
};

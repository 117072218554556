import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../helpers/api/apiEndPoint';

const api = new APICore();

function resetPasswordEmailEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.RESET_PASSWORD_EMAIL, data);
}

function resetPasswordVerifyEndPoint(params: any): any {
    const { data } = params;
    return api.update(URL.RESET_PASSWORD_VERITY, data);
}


export {
    resetPasswordEmailEndPoint,
    resetPasswordVerifyEndPoint
};

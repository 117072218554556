export const OrganisationActionTypes = {
    ORGANISATION_SETTING_FIRST: '@@auth/ORGANISATION_SETTING_FIRST',
    ORGANISATION_SETTING_LOADING: '@@auth/ORGANISATION_SETTING_LOADING',
    ORGANISATION_SETTING_SUCCESS: '@@auth/ORGANISATION_SETTING_SUCCESS',
    ORGANISATION_SETTING_ERROR: '@@auth/ORGANISATION_SETTING_ERROR',
    ORGANISATION_SETTING_RESET: '@@auth/ORGANISATION_SETTING_RESET',

    GET_ORGANISATION_SETTING_FIRST: '@@auth/GET_ORGANISATION_SETTING_FIRST',
    GET_ORGANISATION_SETTING_LOADING: '@@auth/GET_ORGANISATION_SETTING_LOADING',
    GET_ORGANISATION_SETTING_SUCCESS: '@@auth/GET_ORGANISATION_SETTING_SUCCESS',
    GET_ORGANISATION_SETTING_ERROR: '@@auth/GET_ORGANISATION_SETTING_ERROR',
    GET_ORGANISATION_SETTING_RESET: '@@auth/GET_ORGANISATION_SETTING_RESET',

    EMAIL_VERIFY_SEND_FIRST: '@@auth/EMAIL_VERIFY_SEND_FIRST',
    EMAIL_VERIFY_SEND_LOADING: '@@auth/EMAIL_VERIFY_SEND_LOADING',
    EMAIL_VERIFY_SEND_SUCCESS: '@@auth/EMAIL_VERIFY_SEND_SUCCESS',
    EMAIL_VERIFY_SEND_ERROR: '@@auth/EMAIL_VERIFY_SEND_ERROR',
    EMAIL_VERIFY_SEND_RESET: '@@auth/EMAIL_VERIFY_SEND_RESET',

    DELETE_EMAIL_VERIFY_SEND_FIRST: '@@auth/DELETE_EMAIL_VERIFY_SEND_FIRST',
    DELETE_EMAIL_VERIFY_SEND_LOADING: '@@auth/DELETE_EMAIL_VERIFY_SEND_LOADING',
    DELETE_EMAIL_VERIFY_SEND_SUCCESS: '@@auth/DELETE_EMAIL_VERIFY_SEND_SUCCESS',
    DELETE_EMAIL_VERIFY_SEND_ERROR: '@@auth/DELETE_EMAIL_VERIFY_SEND_ERROR',
    DELETE_EMAIL_VERIFY_SEND_RESET: '@@auth/DELETE_EMAIL_VERIFY_SEND_RESET',

    OTP_VERIFY_SEND_FIRST: '@@auth/OTP_VERIFY_SEND_FIRST',
    OTP_VERIFY_SEND_LOADING: '@@auth/OTP_VERIFY_SEND_LOADING',
    OTP_VERIFY_SEND_SUCCESS: '@@auth/OTP_VERIFY_SEND_SUCCESS',
    OTP_VERIFY_SEND_ERROR: '@@auth/OTP_VERIFY_SEND_ERROR',
    OTP_VERIFY_SEND_RESET: '@@auth/OTP_VERIFY_SEND_RESET',
};

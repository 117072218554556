export const ResetPasswordActionTypes = {
    RESET_PASSWORD_EMAIL_FIRST: '@@auth/RESET_PASSWORD_EMAIL_FIRST',
    RESET_PASSWORD_EMAIL_LOADING: '@@auth/RESET_PASSWORD_EMAIL_LOADING',
    RESET_PASSWORD_EMAIL_SUCCESS: '@@auth/RESET_PASSWORD_EMAIL_SUCCESS',
    RESET_PASSWORD_EMAIL_ERROR: '@@auth/RESET_PASSWORD_EMAIL_ERROR',
    RESET_PASSWORD_EMAIL_RESET: '@@auth/RESET_PASSWORD_EMAIL_RESET',

    RESET_PASSWORD_VERIFY_FIRST: '@@auth/RESET_PASSWORD_VERIFY_FIRST',
    RESET_PASSWORD_VERIFY_LOADING: '@@auth/RESET_PASSWORD_VERIFY_LOADING',
    RESET_PASSWORD_VERIFY_SUCCESS: '@@auth/RESET_PASSWORD_VERIFY_SUCCESS',
    RESET_PASSWORD_VERIFY_ERROR: '@@auth/RESET_PASSWORD_VERIFY_ERROR',
    RESET_PASSWORD_VERIFY_RESET: '@@auth/RESET_PASSWORD_VERIFY_RESET',
};

import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";
import * as layoutConstants from "../constants/layout";

// All layouts/containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal";
import FullLayout from "../layouts/Full";
import ErrorPageNotFound from "../helpers/pageNotFound/ErrorPageNotFound";
// lazy load all the views

// auth
// new code 13-12-2023
const Logout = React.lazy(() => import("../pages/account/Logout"));
const AuthIndex = React.lazy(() => import("../auth/AuthIndex"));

const SignupBusinessForm = React.lazy(() =>
  import("../auth/signup/signupBusinessEmail/SignupBusinessEmailForm")
);
const SignupVerifyDetailForm = React.lazy(() =>
  import("../auth/signup/signupVerifyDetail/SignupVerifyDetailForm")
);
const SignupCreatePasswordForm = React.lazy(() =>
  import("../auth/signup/signupCreatePassword/SignupCreatePasswordForm")
);
const SignupOtpSendEmailForm = React.lazy(() =>
  import("../auth/signup/signupOtpSendEmail/SignupOtpSendEmailForm")
);
const WelcomeAccount = React.lazy(() =>
  import("../auth/Welcome/WelcomeAccount")
);
const ForgetPasswordEmail = React.lazy(() =>
  import("../auth/forgetPassword/ForgetPasswordEmail")
);
const ForgetPasswordVerify = React.lazy(() =>
  import("../auth/forgetPassword/ForgetPasswordVerify")
);
const SubClientDeactiveModel = React.lazy(() =>
  import("../auth/Logout/SubClientDeactiveModel")
);
const Industorydetails = React.lazy(() =>
  import("../auth/signup/IndustryAdd/IndustoryMain")
);
const MainBackground = React.lazy(() =>
  import("../auth/signup/signupCreatePassword/MainBackground")
);
const MainOtp = React.lazy(() =>
  import("../auth/signup/signupOtpSendEmail/MainOtp")
);

// new code 13-12-2023
// auth

// // dashboard
const EcommerceDashboard = React.lazy(() =>
  import("../pages/dashboard/Ecommerce")
);
// const EcommerceDashboard = React.lazy(() =>
//   import("../pages/dashboard/Ecommerce")
// );
const OrganisationIndex = React.lazy(() =>
  import("../pages/accountpro/organisation/OrganisationIndex")
);
const SubUser = React.lazy(() =>
  import("../pages/accountpro/subAccountant/SubAccountantIndex")
);
const VatDetails = React.lazy(() =>
  import("../pages/accountpro/Dashboard/vatDetails/VatDetails")
);
const Myaccount = React.lazy(() =>
  import("../pages/accountpro/profile/profileIndex")
);
// const AccountantTable=React.lazy(() =>
// import("../pages/accountpro/searchAccountant/AccountantList/AccountantTable")

const AccountantTable = React.lazy(() =>
  import(
    "../pages/accountpro/searchAccountant/AccountantList/AccountantSearchTable"
  )
);
const IndexDashboard = React.lazy(() =>
  import("../pages/accountpro/Dashboard/DashboardIndex")
);

const AnnouncementIndex = React.lazy(() =>
  import("../pages/accountpro/Announcements/IndexAnnouncement")
);

const Contract = React.lazy(() =>
  import("../pages/accountpro/Dashboard/Contract")
);
const MainTab = React.lazy(() =>
  import("../pages/accountpro/Dashboard/MainTab")
);
const Invitations = React.lazy(() =>
  import("../pages/accountpro/Dashboard/Invitation")
);
const Meeting = React.lazy(() =>
  import("../pages/accountpro/Dashboard/Meeting")
);
const ClientsContracts = React.lazy(() => import("../pages/accountpro/Dashboard/ClientsContracts")
);
const AccessDeniedPage = React.lazy(() =>
  import("../auth/accessDenied/AccessDeniedPage")
);
// // dashboard

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { layout } = useSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case layoutConstants.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  const authorizationRole = useSelector((state) => state?.Auth?.user?.role);

  return useRoutes([
    { path: "/", element: <Root /> },
    {
      // public routes
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "accountant",
          children: [
            { path: "login", element: <LoadComponent component={AuthIndex} /> },
            {
              path: "signup",
              element: <LoadComponent component={SignupBusinessForm} />,
            },
            {
              path: "industorydetails",
              element: <LoadComponent component={Industorydetails} />,
            },
            {
              path: "otp",
              element: <LoadComponent component={MainOtp} />,
            },
            {
              path: "password",
              element: <LoadComponent component={MainBackground} />,
            },
            {
              path: "signupVerify",
              element: <LoadComponent component={SignupVerifyDetailForm} />,
            },
            {
              path: "signupCreatePassword",
              element: <LoadComponent component={SignupCreatePasswordForm} />,
            },
            {
              path: "signupOtpSend/:id/:token",
              element: <LoadComponent component={SignupOtpSendEmailForm} />,
            },
            {
              path: "welcomeAccount",
              element: <LoadComponent component={WelcomeAccount} />,
            },
            {
              path: "forgetPasswordEmail",
              element: <LoadComponent component={ForgetPasswordEmail} />,
            },
            {
              path: "reset-password",
              element: <LoadComponent component={ForgetPasswordVerify} />,
            },
            {
              path: "reset-password/:id",
              element: <LoadComponent component={ForgetPasswordVerify} />,
            },
            { path: "logout", element: <LoadComponent component={Logout} /> },
            {
              path: "accessDeniedPage",
              element: <LoadComponent component={AccessDeniedPage} />,
            },
            {
              path: "accountLock",
              element: <LoadComponent component={SubClientDeactiveModel} />,
            },
            {
              path: "*",
              element: <LoadComponent component={ErrorPageNotFound} />,
            },
          ],
        },
      ],
    },

    {
      // auth protected routes
      path: "/",
      element: <PrivateRoute roles={authorizationRole} component={Layout} />,
      children: [
        {
          path: "dashboard",
          children: [
            {
              path: "ecommerce",
              element: <LoadComponent component={EcommerceDashboard} />,
            },
            {
              path: "account",
              element: <LoadComponent component={Myaccount} />,
            },
            {
              path: "organization",
              element: <LoadComponent component={OrganisationIndex} />,
            },
            {
              path: "subuser",
              element: <LoadComponent component={SubUser} />,
            },
            {
              path: "accountantTable",
              element: <LoadComponent component={AccountantTable} />,
            },

            {
              path: "*",
              element: <LoadComponent component={ErrorPageNotFound} />,
            },
            {
              path: "vatdetails/:id",
              element: <LoadComponent component={VatDetails} />,
            },
            {
              path: "maintab",
              element: <LoadComponent component={MainTab} />,
            },
            {
              path: "contract",
              element: <LoadComponent component={Contract} />,
            },
            {
              path: "invitation",
              element: <LoadComponent component={Invitations} />,
            },
            {
              path: "meeting",
              element: <LoadComponent component={Meeting} />,
            },
            {
              path: "getclientscontracts/:clientId",
              element: <LoadComponent component={ClientsContracts} />,
            },
          ],
        },

        {
          path: "announcement",
          element: <LoadComponent component={AnnouncementIndex} />,
        },
      ],
    },
  ]);
};

export { AllRoutes };

export const BusinessEmailActionTypes = {
    BUSINESS_EMAIL_FIRST: '@@auth/BUSINESS_EMAIL_FIRST',
    BUSINESS_EMAIL_LOADING: '@@auth/BUSINESS_EMAIL_LOADING',
    BUSINESS_EMAIL_SUCCESS: '@@auth/BUSINESS_EMAIL_SUCCESS',
    BUSINESS_EMAIL_ERROR: '@@auth/BUSINESS_EMAIL_ERROR',
    BUSINESS_EMAIL_RESET: '@@auth/BUSINESS_EMAIL_RESET',

    BUSINESS_EMAIL_VERIFY_FIRST: '@@auth/BUSINESS_EMAIL_VERIFY_FIRST',
    BUSINESS_EMAIL_VERIFY_LOADING: '@@auth/BUSINESS_EMAIL_VERIFY_LOADING',
    BUSINESS_EMAIL_VERIFY_SUCCESS: '@@auth/BUSINESS_EMAIL_VERIFY_SUCCESS',
    BUSINESS_EMAIL_VERIFY_ERROR: '@@auth/BUSINESS_EMAIL_VERIFY_ERROR',
    BUSINESS_EMAIL_VERIFY_RESET: '@@auth/BUSINESS_EMAIL_VERIFY_RESET',
};

import { OrganisationActionTypes } from './constants';

const ORGANISATION_SETTING_INITIAL_STATE = {
    organisationSetting: [],
    loading: false,
};

const GET_ORGANISATION_SETTING_INITIAL_STATE = {
    getOrganisationSetting: [],
    loading: false,
};

const EMAIL_VERIFY_SEND_INITIAL_STATE = {
    emailVerifySend: [],
    loading: false,
};

const OTP_VERIFY_SEND_INITIAL_STATE = {
    otpVerifySend: [],
    loading: false,
};

const DELETE_EMAIL_VERIFY_INITIAL_STATE = {
    deleteEmailVerifySend: [],
    loading: false,
};


const organisationSettingReducer = (
    state = ORGANISATION_SETTING_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case OrganisationActionTypes.ORGANISATION_SETTING_LOADING:
            return {
                organisationSetting: state.organisationSetting,
                loading: true,
            };
        case OrganisationActionTypes.ORGANISATION_SETTING_SUCCESS:
            return {
                organisationSetting: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.ORGANISATION_SETTING_ERROR:
            return {
                organisationSetting: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.ORGANISATION_SETTING_RESET:
            return ORGANISATION_SETTING_INITIAL_STATE;
        default:
            return state;
    }
};

const getOrganisationSettingReducer = (
    state = GET_ORGANISATION_SETTING_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case OrganisationActionTypes.GET_ORGANISATION_SETTING_LOADING:
            return {
                getOrganisationSetting: state.getOrganisationSetting,
                loading: true,
            };
        case OrganisationActionTypes.GET_ORGANISATION_SETTING_SUCCESS:
            return {
                getOrganisationSetting: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.GET_ORGANISATION_SETTING_ERROR:
            return {
                getOrganisationSetting: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.GET_ORGANISATION_SETTING_RESET:
            return GET_ORGANISATION_SETTING_INITIAL_STATE;
        default:
            return state;
    }
};

const emailSendVerifyReducer = (
    state = EMAIL_VERIFY_SEND_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case OrganisationActionTypes.EMAIL_VERIFY_SEND_LOADING:
            return {
                emailVerifySend: state.emailVerifySend,
                loading: true,
            };
        case OrganisationActionTypes.EMAIL_VERIFY_SEND_SUCCESS:
            return {
                emailVerifySend: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.EMAIL_VERIFY_SEND_ERROR:
            return {
                emailVerifySend: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.EMAIL_VERIFY_SEND_RESET:
            return EMAIL_VERIFY_SEND_INITIAL_STATE;
        default:
            return state;
    }
};

const deleteEmailSendVerifyReducer = (
    state = DELETE_EMAIL_VERIFY_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_LOADING:
            return {
                deleteEmailVerifySend: state.deleteEmailVerifySend,
                loading: true,
            };
        case OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_SUCCESS:
            return {
                deleteEmailVerifySend: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_ERROR:
            return {
                deleteEmailVerifySend: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_RESET:
            return EMAIL_VERIFY_SEND_INITIAL_STATE;
        default:
            return state;
    }
};

const otpSendVerifyReducer = (
    state = OTP_VERIFY_SEND_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case OrganisationActionTypes.OTP_VERIFY_SEND_LOADING:
            return {
                otpVerifySend: state.otpVerifySend,
                loading: true,
            };
        case OrganisationActionTypes.OTP_VERIFY_SEND_SUCCESS:
            return {
                otpVerifySend: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.OTP_VERIFY_SEND_ERROR:
            return {
                otpVerifySend: action.payload,
                loading: false,
            };
        case OrganisationActionTypes.OTP_VERIFY_SEND_RESET:
            return OTP_VERIFY_SEND_INITIAL_STATE;
        default:
            return state;
    }
};

export {
    organisationSettingReducer,
    getOrganisationSettingReducer,
    emailSendVerifyReducer,
    deleteEmailSendVerifyReducer,
    otpSendVerifyReducer
}

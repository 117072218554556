// @flow
import { OrganisationActionTypes } from './constants';

export const organisationSettingActions = (data) => ({
    type: OrganisationActionTypes.ORGANISATION_SETTING_FIRST,
    data,
});

export const getOrganisationSettingActions = (data) => ({
    type: OrganisationActionTypes.GET_ORGANISATION_SETTING_FIRST,
    data,
});

export const emailVerifySendActions = (data) => ({
    type: OrganisationActionTypes.EMAIL_VERIFY_SEND_FIRST,
    data,
});

export const deleteEmailVerifySendActions = (data) => ({
    type: OrganisationActionTypes.DELETE_EMAIL_VERIFY_SEND_FIRST,
    data,
});

export const otpVerifySendActions = (data) => ({
    type: OrganisationActionTypes.OTP_VERIFY_SEND_FIRST,
    data,
});
export const AccountSignupActionTypes = {
    GET_SKILL_FIRST: '@@auth/GET_SKILL_FIRST',
    GET_SKILL_LOADING: '@@auth/GET_SKILL_LOADING',
    GET_SKILL_SUCCESS: '@@auth/GET_SKILL_SUCCESS',
    GET_SKILL_ERROR: '@@auth/GET_SKILL_ERROR',
    GET_SKILL_RESET: '@@auth/GET_SKILL_RESET',

    SIGNUP_ACCOUNTANT_FIRST: '@@auth/SIGNUP_ACCOUNTANT_FIRST',
    SIGNUP_ACCOUNTANT_LOADING: '@@auth/SIGNUP_ACCOUNTANT_LOADING',
    SIGNUP_ACCOUNTANT_SUCCESS: '@@auth/SIGNUP_ACCOUNTANT_SUCCESS',
    SIGNUP_ACCOUNTANT_ERROR: '@@auth/SIGNUP_ACCOUNTANT_ERROR',
    SIGNUP_ACCOUNTANT_RESET: '@@auth/SIGNUP_ACCOUNTANT_RESET',

    GET_FIRST_ITAA: '@@auth/GET_FIRST_ITAA',
    GET_LOADING_ITAA: '@@auth/GET_LOADING_ITAA',
    GET_SUCCESS_ITAA: '@@auth/GET_SUCCESS_ITAA',
    GET_ERROR_ITAA: '@@auth/GET_ERROR_ITAA',
    GET_RESET_ITAA: '@@auth/GET_RESET_ITAA',

    GET_FIRST_INDUSTORY: '@@auth/GET_FIRST_INDUSTORY',
    GET_LOADING_INDUSTORY: '@@auth/GET_LOADING_INDUSTORY',
    GET_SUCCESS_INDUSTORY: '@@auth/GET_SUCCESS_INDUSTORY',
    GET_ERROR_INDUSTORY: '@@auth/GET_ERROR_INDUSTORY',
    GET_RESET_INDUSTORY: '@@auth/GET_RESET_INDUSTORY',

    //   GET
    GET_LANGUAGES: "GET_LANGUAGES",
    GET_LANGUAGES_LOADING: "GET_LANGUAGES_LOADING",
    GET_LANGUAGES_SUCCESS: "GET_LANGUAGES_SUCCESS",
    GET_LANGUAGES_ERROR: "GET_LANGUAGES_ERROR",
    GET_LANGUAGES_RESET: "GET_LANGUAGES_RESET",

};

export const DashboardActionTypes = {

    GET_ACCOUNTANT_DASHBOARD_FIRST: '@@auth/GET_ACCOUNTANT_DASHBOARD_FIRST',
    GET_ACCOUNTANT_DASHBOARD_LOADING: '@@auth/GET_ACCOUNTANT_DASHBOARD_LOADING',
    GET_ACCOUNTANT_DASHBOARD_SUCCESS: '@@auth/GET_ACCOUNTANT_DASHBOARD_SUCCESS',
    GET_ACCOUNTANT_DASHBOARD_ERROR: '@@auth/GET_ACCOUNTANT_DASHBOARD_ERROR',
    GET_ACCOUNTANT_DASHBOARD_RESET: '@@auth/GET_ACCOUNTANT_DASHBOARD_RESET',

    GET_CLIENT_INVITATION_LIST_DASHBOARD_FIRST: '@@auth/GET_CLIENT_INVITATION_LIST_DASHBOARD_FIRST',
    GET_CLIENT_INVITATION_LIST_DASHBOARD_LOADING: '@@auth/GET_CLIENT_INVITATION_LIST_DASHBOARD_LOADING',
    GET_CLIENT_INVITATION_LIST_DASHBOARD_SUCCESS: '@@auth/GET_CLIENT_INVITATION_LIST_DASHBOARD_SUCCESS',
    GET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR: '@@auth/GET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR',
    GET_CLIENT_INVITATION_LIST_DASHBOARD_RESET: '@@auth/GET_CLIENT_INVITATION_LIST_DASHBOARD_RESET',

    ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_FIRST: '@@auth/ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_FIRST',
    ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_LOADING: '@@auth/ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_LOADING',
    ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_SUCCESS: '@@auth/ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_SUCCESS',
    ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR: '@@auth/ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_ERROR',
    ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_RESET: '@@auth/ACCEPET_CLIENT_INVITATION_LIST_DASHBOARD_RESET',

    // Docusign token Get
    GET_FIRST_DOCUSIGN_API_KEY: '@@auth/GET_FIRST_DOCUSIGN_API_KEY',
    GET_LOADING_DOCUSIGN_API_KEY: '@@auth/GET_LOADING_DOCUSIGN_API_KEY',
    GET_SUCCESS_DOCUSIGN_API_KEY: '@@auth/GET_SUCCESS_DOCUSIGN_API_KEY',
    GET_ERROR_DOCUSIGN_API_KEY: '@@auth/GET_ERROR_DOCUSIGN_API_KEY',
    GET_RESET_DOCUSIGN_API_KEY: '@@auth/GET_RESET_DOCUSIGN_API_KEY',

    // Get Contract List
    GET_FIRST_CONTRACT_LIST: '@@auth/GET_FIRST_CONTRACT_LIST',
    GET_LOADING_CONTRACT_LIST: '@@auth/GET_LOADING_CONTRACT_LIST',
    GET_SUCCESS_CONTRACT_LIST: '@@auth/GET_SUCCESS_CONTRACT_LIST',
    GET_ERROR_CONTRACT_LIST: '@@auth/GET_ERROR_CONTRACT_LIST',
    GET_RESET_CONTRACT_LIST: '@@auth/GET_RESET_CONTRACT_LIST',

    // Get Client Contract List
    GET_FIRST_CLIENT_CONTRACT_LIST: '@@auth/GET_FIRST_CLIENT_CONTRACT_LIST',
    GET_LOADING_CLIENT_CONTRACT_LIST: '@@auth/GET_LOADING_CLIENT_CONTRACT_LIST',
    GET_SUCCESS_CLIENT_CONTRACT_LIST: '@@auth/GET_SUCCESS_CLIENT_CONTRACT_LIST',
    GET_ERROR_CLIENT_CONTRACT_LIST: '@@auth/GET_ERROR_CLIENT_CONTRACT_LIST',
    GET_RESET_CLIENT_CONTRACT_LIST: '@@auth/GET_RESET_CONTRACT_LIST',
};

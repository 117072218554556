import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../helpers/api/apiEndPoint';

const api = new APICore();

function getCompanyNameEndPoint(params: any): any {
    const searchCompany = params?.data?.searchCompany;
    // console.log(searchCompany, 'api')
    return api.get(`${URL.GET_COMPANIES_BY_SEARCH}search=${searchCompany}&type=Accountant`,);
}

function registerUserEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.REGISTER_USER, data);
}

function companyCheckVerifyEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.COMPANY_CHECK_VERIFY, data);
}

function companyCreateEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.COMPANY_CREATE, data);
}

export {
    getCompanyNameEndPoint,
    registerUserEndPoint,
    companyCheckVerifyEndPoint,
    companyCreateEndPoint
};

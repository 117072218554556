import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";

const api = new APICore();

function organisationSettingEndPoint(params: any): any {
  const { data } = params;
  return api.update(URL.UPDATE_ORGNISATION, data);
}

function getOrganisationSettingEndPoint(params: any): any {
  const { data } = params;
  return api.get(URL.GET_ORGNISATION);
}

function emailVerifySendEndPoint(params: any): any {
  const { data } = params;
  return api.create(URL.EMAIL_VERIFY_SEND, data);
}

function DeleteEmailVerifySendEndPoint(params: any): any {
  const { data } = params;
  return api.delete(`${URL.EMAIL_VERIFY_DELETE}index=${data?.index}`);
}

function otpVerifySendEndPoint(params: any): any {
  const { data } = params;
  return api.create(URL.OTP_VERIFY_SEND, data);
}

export {
  organisationSettingEndPoint,
  getOrganisationSettingEndPoint,
  emailVerifySendEndPoint,
  DeleteEmailVerifySendEndPoint,
  otpVerifySendEndPoint,
};

// @flow
import { AccountantSearchActionTypes } from "./constants";

export const getSearchAccountantListAction = (data) => ({
  type: AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_FIRST,
  data
});

export const ignoreClientRequestAction = (data): AuthAction => ({
  type: AccountantSearchActionTypes?.IGNORE_CLIENT_REQUEST_FIRST,
  payload: data,
});

export const getMeetingStatusDetailsAction = (data) => ({
  type: AccountantSearchActionTypes?.GET_MEETING_STATUS_DETAILS_LIST_FIRST,
  data,
});

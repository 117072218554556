import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { subAccountant } from "./constants";
import {
  addSubAccountantApi,
  deleteSubAccountantApi,
  getSubAccountantApi,
  updateSubAccountantApi,
} from "./api";

function* getSubAccountantFunction({ payload }) {
  try {
    yield put({
      type: subAccountant.GET_SUBACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(getSubAccountantApi, { payload });
    if (response.data.status) {
      yield put({
        type: subAccountant.GET_SUBACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: subAccountant.GET_SUBACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: subAccountant.GET_SUBACCOUNTANT_ERROR,
      payload: { message: error?.message },
    });
  }
}

function* addsubAccountantFunction({ payload }) {
  try {
    yield put({
      type: subAccountant.ADD_SUBACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(addSubAccountantApi, { payload });
    if (response.data.status) {
      yield put({
        type: subAccountant.ADD_SUBACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: subAccountant.ADD_SUBACCOUNTANT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: subAccountant.ADD_SUBACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: subAccountant.ADD_SUBACCOUNTANT_ERROR,
      payload: error?.data,
    });
    yield put({
      type: subAccountant.ADD_SUBACCOUNTANT_RESET,
      payload: {},
    });
  }
}

function* updateSubAccountantFunction({ payload }) {
  try {
    yield put({
      type: subAccountant.UPDATE_SUBACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(updateSubAccountantApi, { payload });
    if (response.data.status) {
      yield put({
        type: subAccountant.UPDATE_SUBACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: subAccountant.UPDATE_SUBACCOUNTANT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: subAccountant.UPDATE_SUBACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: subAccountant.UPDATE_SUBACCOUNTANT_ERROR,
      payload: error?.data,
    });
  }
}

function* deleteSubAccountantFunction(data) {
  try {
    yield put({
      type: subAccountant.DELETE_SUBACCOUNTANT_LOADING,
      payload: {},
    });
    const response = yield call(deleteSubAccountantApi, data);
    if (response.data.status) {
      yield put({
        type: subAccountant.DELETE_SUBACCOUNTANT_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: subAccountant.DELETE_SUBACCOUNTANT_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: subAccountant.DELETE_SUBACCOUNTANT_ERROR,
        payload: { ...response.data },
      });
      yield put({
        type: subAccountant.DELETE_SUBACCOUNTANT_RESET,
        payload: {},
      });
    }
  } catch (error) {
    yield put({
      type: subAccountant.DELETE_SUBACCOUNTANT_ERROR,
      payload: error,
    });
    yield put({
      type: subAccountant.DELETE_SUBACCOUNTANT_RESET,
      payload: {},
    });
  }
}

export function* getsubAccountantSaga(): any {
  yield takeEvery(subAccountant.GET_SUBACCOUNTANT, getSubAccountantFunction);
}

export function* addsubAccountantSaga(): any {
  yield takeEvery(subAccountant.ADD_SUBACCOUNTANT, addsubAccountantFunction);
}

export function* updateSubAccountantSaga(): any {
  yield takeEvery(
    subAccountant.UPDATE_SUBACCOUNTANT,
    updateSubAccountantFunction
  );
}
export function* deleteSubAccountantSaga(): any {
  yield takeEvery(
    subAccountant.DELETE_SUBACCOUNTANT,
    deleteSubAccountantFunction
  );
}

function* subAccountantSaga(): any {
  yield all([
    fork(getsubAccountantSaga),
    fork(addsubAccountantSaga),
    fork(updateSubAccountantSaga),
    fork(deleteSubAccountantSaga),
  ]);
}

export default subAccountantSaga;

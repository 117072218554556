import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import AccountType from "../../accountPro/profile/constant";
import {
  uploadProfileApi,
  //  getProfileApi,
  uploadPasswordApi,
  mobileNumberVerifyUpdateEndPoint,
} from "./api";

function* updateProfileFunction({ payload }) {
  try {
    yield put({
      type: AccountType.UPDATE_PROFILE_LOADING,
      payload: {},
    });
    const response = yield call(uploadProfileApi, { payload });
    if (response.data.status === 200) {
      yield put({
        type: AccountType.UPDATE_PROFILE_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: AccountType.UPDATE_PROFILE_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: AccountType.UPDATE_PROFILE_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: AccountType.UPDATE_PROFILE_ERROR,
      payload: { message: error },
    });
  }
}

function* updatePasswordFunction({ payload }) {
  try {
    const response = yield call(uploadPasswordApi, { payload });
    if (response.data != null) {
      yield put({
        type: AccountType.UPDATE_PASSWORD_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: AccountType.UPDATE_PASSWORD_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: AccountType.UPDATE_PASSWORD_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: AccountType.UPDATE_PASSWORD_ERROR,
      payload: error?.data,
    });
  }
}

// function* getProfileFunction({ payload }) {
//     try {

//         const response = yield call(getProfileApi, { payload });
//         if (response.data.status === 200) {
//             yield put({
//                 type: AccountType.GET_PROFILE_SUCCESS,
//                 payload: { ...response.data },
//             });

//         }
//         else {
//             yield put({
//                 type: AccountType.GET_PROFILE_ERROR,
//                 payload: { ...response.data }
//             });
//         }

//     } catch (error) {
//         yield put({
//             type: AccountType.GET_PROFILE_ERROR,
//             payload: { message: error?.message }
//         });

//     }
// }

// function* mobileNumberVerifyFunction(data) {
//   try {
//     yield put({
//       type: AccountType.MOBILE_NUMBER_VERIFY_LOADING,
//       payload: {},
//     });
//     const response = yield call(mobileNumberVerifyUpdateEndPoint, data);
//     if (response.data.status) {
//       yield put({
//         type: AccountType.MOBILE_NUMBER_VERIFY_SUCCESS,
//         payload: { ...response.data },
//       });
//       yield put({
//         type: AccountType.MOBILE_NUMBER_VERIFY_RESET,
//         payload: {},
//       });
//     } else {
//       yield put({
//         type: AccountType.MOBILE_NUMBER_VERIFY_ERROR,
//         payload: { ...response.data },
//       });
//     }
//   } catch (error) {
//     yield put({
//       type: AccountType.MOBILE_NUMBER_VERIFY_ERROR,
//       payload: error?.data,
//     });
//     yield put({
//       type: AccountType.MOBILE_NUMBER_VERIFY_RESET,
//       payload: {},
//     });
//   }
// }

export function* updateProfileSaga(): any {
  yield takeEvery(AccountType.UPDATE_PROFILE, updateProfileFunction);
}

// export function* acctioMobileNumberVerify(): any {
//   yield takeEvery(
//     AccountType.MOBILE_NUMBER_VERIFY_FIRST,
//     mobileNumberVerifyFunction
//   );
// }

// export function* getProfileSaga(): any {
//     yield takeEvery(AccountType.GET_PROFILE, getProfileFunction);
// }
export function* getPasswordSaga(): any {
  yield takeEvery(AccountType.UPDATE_PASSWORD, updatePasswordFunction);
}

function* ProfileSaga(): any {
  yield all([
    fork(updateProfileSaga),
    // fork(acctioMobileNumberVerify),
    fork(getPasswordSaga),
  ]);
}
export default ProfileSaga;

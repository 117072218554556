import { announcements } from "./constant";

export const getAnnoucementList = (data): AuthAction => ({
  type: announcements.GET_ANNOUNCEMENT,
  payload: data,
});

export const searchClientList = (data): AuthAction => ({
  type: announcements.SEARCH_CLIENT,
  payload: data,
});

export const postAnnouncement = (data): AuthAction => ({
  type: announcements.POST_ANNOUNCEMENT,
  payload: data,
});

export const updateAnnouncement = (data): AuthAction => ({
  type: announcements?.UPDATE_ANNOUNCEMENT,
  payload: data,
});

export const deleteAnnouncement = (id): AuthAction => {
  return {
    type: announcements?.DELETE_ANNOUNCEMENT,
    id,
  };
};

export const getAnnouncements = (data) => ({
  type: announcements?.GET_ANNOUNCEMENT_ROLE,
  data,
});

import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function getAnnouncementsApi(data): any {
  return api.get(
    `${URL.GET_ANNOUNCEMENT}?role=Accountant&page=${data?.payload?.page ? data?.payload?.page : 1
    }&limit=10`
  );
}

export function searchClientApi(data): any {
  return api.get(`${URL.SEARCH_CLIENT}?name=${data?.payload?.name}&userId=${data?.payload?.userId}`);
}

export function postAnnouncementsApi(data): any {
  return api.create(`${URL.POST_ANNOUNCEMENT}`, data?.payload);
}

export function updateAnnouncementsApi(data): any {
  return api.update(`${URL.UPDATE_ANNOUNCEMENT}`, data?.payload);
}

export function deleteAnnouncementsApi(data): any {
  const id = data?.payload;
  // console.log(data, "here is the dataaaa");
  return api.delete(URL.DELETE_ANNOUNCEMENT, data?.id);
}
export function getAnnouncementsApiForAccountant(params: any): any {
  return api.get(URL.GET_ANNOUNCEMENT_ACCOUNTANT);
}

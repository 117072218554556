import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { ResetPasswordActionTypes } from './constants';
import {
    resetPasswordEmailEndPoint,
    resetPasswordVerifyEndPoint
} from './api';


function* resetPasswordEmailFunction(data) {
    try {
        yield put({
            type: ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_LOADING,
            payload: {},
        });
        const response = yield call(resetPasswordEmailEndPoint, data);
        if (response.data.status) {
            yield put({
                type: ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        // console.log(error?.data,'error?.data')
        yield put({
            type: ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_ERROR,
            payload: error?.data,
        });
        yield put({
            type: ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_RESET,
            payload: {},
        });
    }
}

function* resetPasswordVerifyFunction(data) {
    try {
        yield put({
            type: ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_LOADING,
            payload: {},
        });
        const response = yield call(resetPasswordVerifyEndPoint, data);
        if (response.data.status) {
            yield put({
                type: ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        // console.log(error, 'error500')

        yield put({
            type: ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_ERROR,
            payload: error?.data,
        });
        yield put({
            type: ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_RESET,
            payload: {},
        });
    }
}


export function* acctionResetPasswordEmail(): any {
    yield takeEvery(ResetPasswordActionTypes.RESET_PASSWORD_EMAIL_FIRST, resetPasswordEmailFunction);
}

export function* acctionResetPasswordVerify(): any {
    yield takeEvery(ResetPasswordActionTypes.RESET_PASSWORD_VERIFY_FIRST, resetPasswordVerifyFunction);
}

function* resetPasswordSaga(): any {
    yield all([
        fork(acctionResetPasswordEmail),
        fork(acctionResetPasswordVerify),
    ]);
}

export default resetPasswordSaga;

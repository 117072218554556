import * as URL from "../../helpers/api/apiEndPoint";
import { APICore } from "../../helpers/api/apiCore";
const api = new APICore();

export function getSubAccountantApi(data): any {
  // console.log(data);
  return api.get(
    `${URL.GET_SUBACCOUNTANT}?limit=10&page=${data?.payload?.page ? data?.payload?.page : 1
    }`
  );
}

export function addSubAccountantApi(data): any {
  return api.create(URL.ADD_SUBACCOUNTANT, data.payload);
}

export function updateSubAccountantApi(data): any {
  return api.update(URL.UPDATE_SUBACCOUNTANT, data?.payload);
}

export function deleteSubAccountantApi(data): any {
  // console.log(data?.payload, "jjhjj");
  const { id } = data?.payload;
  return api.update(`${URL.DELETE_SUBACCOUNTANT}userId=${id}`);
}

export const subAccountant = {
  // Get
  GET_SUBACCOUNTANT: "GET_SUBACCOUNTANT",
  GET_SUBACCOUNTANT_LOADING: "GET_SUBACCOUNTANT_LOADING",
  GET_SUBACCOUNTANT_SUCCESS: "GET_SUBACCOUNTANT_SUCCESS",
  GET_SUBACCOUNTANT_ERROR: "GET_SUBACCOUNTANT_ERROR",
  GET_SUBACCOUNTANT_RESET: "GET_SUBACCOUNTANT_RESET",

  // Post
  ADD_SUBACCOUNTANT: "ADD_SUBACCOUNTANT",
  ADD_SUBACCOUNTANT_LOADING: "ADD_SUBACCOUNTANT_LOADING",
  ADD_SUBACCOUNTANT_SUCCESS: "ADD_SUBACCOUNTANT_SUCCESS",
  ADD_SUBACCOUNTANT_ERROR: "ADD_SUBACCOUNTANT_ERROR",
  ADD_SUBACCOUNTANT_RESET: "ADD_SUBACCOUNTANT_RESET",

  // Update
  UPDATE_SUBACCOUNTANT: "UPDATE_SUBACCOUNTANT",
  UPDATE_SUBACCOUNTANT_LOADING: "UPDATE_SUBACCOUNTANT_LOADING",
  UPDATE_SUBACCOUNTANT_SUCCESS: "UPDATE_SUBACCOUNTANT_SUCCESS",
  UPDATE_SUBACCOUNTANT_ERROR: "UPDATE_SUBACCOUNTANT_ERROR",
  UPDATE_SUBACCOUNTANT_RESET: "UPDATE_SUBACCOUNTANT_RESET",

  // Delete
  DELETE_SUBACCOUNTANT: "DELETE_SUBACCOUNTANT",
  DELETE_SUBACCOUNTANT_LOADING: "DELETE_SUBACCOUNTANT_LOADING",
  DELETE_SUBACCOUNTANT_SUCCESS: "DELETE_SUBACCOUNTANT_SUCCESS",
  DELETE_SUBACCOUNTANT_ERROR: "DELETE_SUBACCOUNTANT_ERROR",
  DELETE_SUBACCOUNTANT_RESET: "DELETE_SUBACCOUNTANT_RESET",
};

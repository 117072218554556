import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../helpers/api/apiEndPoint';

const api = new APICore();

function otpSendEmailEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.VERIFY_EMAIL_OTP, data);
}

function subAccountantOtpSendEmailEndPoint(params: any): any {
    const { data } = params;
    return api.create(`${URL.SUB_ACCOUNTANT_EMAIL_SEND_OTP}email=${data?.getEmailUrl}&type=${data?.type}&token=${data?.token}`);
}


export {
    otpSendEmailEndPoint,
    subAccountantOtpSendEmailEndPoint
};

import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { AccountantSearchActionTypes } from './constants';
import {
    getMeetingStatusDetailsApiEndPoint,
    getSearchAccountantListEndPoint,
    ignoreClientApiEndPoint,
} from './api';


function* getSearchAccountantFunction(data) {
    try {
        yield put({
            type: AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_LOADING,
            payload: {},
        });
        const response = yield call(getSearchAccountantListEndPoint, data);
        if (response.data.status) {
            yield put({
                type: AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_SUCCESS,
                payload: { ...response.data },
            });

        } else {
            yield put({
                type: AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_ERROR,
            payload: error?.data,
        });
        yield put({
            type: AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_RESET,
            payload: {},
        });
    }
}

function* ignoreClientRequestFunction({ payload }) {
    try {
        yield put({
            type: AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_LOADING,
            payload: {},
        });
        const response = yield call(ignoreClientApiEndPoint, { payload });
        if (response.data.status) {
            yield put({
                type: AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_SUCCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_ERROR,
            payload: error?.data,
        });
    }
}

function* getMeetingStatusFunction() {
    try {
        yield put({
            type: AccountantSearchActionTypes.GET_MEETING_STATUS_DETAILS_LIST_LOADING,
            payload: {},
        });
        const response = yield call(getMeetingStatusDetailsApiEndPoint);
        if (response.data.status) {
            yield put({
                type: AccountantSearchActionTypes.GET_MEETING_STATUS_DETAILS_LIST_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: AccountantSearchActionTypes.GET_MEETING_STATUS_DETAILS_LIST_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: AccountantSearchActionTypes.GET_MEETING_STATUS_DETAILS_LIST_ERROR,
            payload: { message: error?.message },
        });
    }
}


export function* actionGetSearchAccountant(): any {
    yield takeEvery(AccountantSearchActionTypes.GET_SEARCH_ACCOUNTANT_LIST_FIRST, getSearchAccountantFunction);
}
export function* actionIgnoreClientRequest(): any {
    yield takeEvery(AccountantSearchActionTypes.IGNORE_CLIENT_REQUEST_FIRST, ignoreClientRequestFunction);
}
export function* actiongetMeetingStatus(): any {
    yield takeEvery(AccountantSearchActionTypes.GET_MEETING_STATUS_DETAILS_LIST_FIRST, getMeetingStatusFunction);
}


function* accountantSearchSaga(): any {
    yield all([
        fork(actionGetSearchAccountant),
        fork(actionIgnoreClientRequest),
        fork(actiongetMeetingStatus),
    ]);
}

export default accountantSearchSaga;

import { BankAccountDetailActionTypes } from './constants';

const ACCOUNT_DETAIL_INITIAL_STATE = {
    accountDetail: [],
    loading: false,
};

const ACCOUNT_SETTING_INITIAL_STATE = {
    accountSetting: [],
    loading: false,
};

const GET_ACCOUNTANT_PROFILE_INITIAL_STATE = {
    getAccountantProfile: [],
    loading: false,
};
const MOBILE_NUMBER_VERIFY_INITIAL_STATE = {
    mobileVerify: [],
    loading: false,
};
const MOBILE_NUMBER_VERIFY_OTP_SEND_INITIAL_STATE = {
    mobileOtpSend: [],
    loading: false,
};

const accountDetailReducer = (
    state = ACCOUNT_DETAIL_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case BankAccountDetailActionTypes.ACCOUNT_DETAIL_LOADING:
            return {
                accountDetail: state.accountDetail,
                loading: true,
            };
        case BankAccountDetailActionTypes.ACCOUNT_DETAIL_SUCCESS:
            return {
                accountDetail: action.payload,
                loading: false,
            };
        case BankAccountDetailActionTypes.ACCOUNT_DETAIL_ERROR:
            return {
                accountDetail: action.payload,
                loading: false,
            };
        case BankAccountDetailActionTypes.ACCOUNT_DETAIL_RESET:
            return ACCOUNT_DETAIL_INITIAL_STATE;
        default:
            return state;
    }
};

const accountSettingReducer = (
    state = ACCOUNT_SETTING_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case BankAccountDetailActionTypes.ACCOUNT_SETTING_LOADING:
            return {
                accountSetting: state.accountSetting,
                loading: true,
            };
        case BankAccountDetailActionTypes.ACCOUNT_SETTING_SUCCESS:
            return {
                accountSetting: action.payload,
                loading: false,
            };
        case BankAccountDetailActionTypes.ACCOUNT_SETTING_ERROR:
            return {
                accountSetting: action.payload,
                loading: false,
            };
        case BankAccountDetailActionTypes.ACCOUNT_SETTING_RESET:
            return ACCOUNT_SETTING_INITIAL_STATE;
        default:
            return state;
    }
};

const getAccountantProfileReducer = (
    state = GET_ACCOUNTANT_PROFILE_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case BankAccountDetailActionTypes.GET_PROFILE_ACCOUNTANT_LOADING:
            return {
                getAccountantProfile: state.getAccountantProfile,
                loading: true,
            };
        case BankAccountDetailActionTypes.GET_PROFILE_ACCOUNTANT_SUCCESS:
            return {
                getAccountantProfile: action.payload,
                loading: false,
            };
        case BankAccountDetailActionTypes.GET_PROFILE_ACCOUNTANT_ERROR:
            return {
                getAccountantProfile: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

 const mobileNumberVerifyReducer = (
    state = MOBILE_NUMBER_VERIFY_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_LOADING:
            return {
                mobileVerify: state.mobileVerify,
                loading: true,
            };
        case BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_SUCCESS:
            return {
                mobileVerify: action.payload,
                loading: false,
            };
        case BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_ERROR:
            return {
                mobileVerify: action.payload,
                loading: false,
            };
        case BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_RESET:
            return MOBILE_NUMBER_VERIFY_INITIAL_STATE;
        default:
            return state;
    }
};

 const mobileNumberVerifyOtpSendReducer = (
    state = MOBILE_NUMBER_VERIFY_OTP_SEND_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_LOADING:
            return {
                mobileOtpSend: state.mobileOtpSend,
                loading: true,
            };
        case BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_SUCCESS:
            return {
                mobileOtpSend: action.payload,
                loading: false,
            };
        case BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_ERROR:
            return {
                mobileOtpSend: action.payload,
                loading: false,
            };
        case BankAccountDetailActionTypes.MOBILE_NUMBER_VERIFY_OTP_SEND_RESET:
            return MOBILE_NUMBER_VERIFY_OTP_SEND_INITIAL_STATE;
        default:
            return state;
    }
};


export {
    accountDetailReducer,
    accountSettingReducer,
    getAccountantProfileReducer,
    mobileNumberVerifyReducer,
    mobileNumberVerifyOtpSendReducer

}

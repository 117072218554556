import { APICore } from '../../../../helpers/api/apiCore';
import * as URL from '../../../../helpers/api/apiEndPoint';

const api = new APICore();

function accountDetailEndPoint(params) {
    const { data } = params;

    return api.update(URL.ACCOUNT_DETAIL_ACCOUNT, data);
}

function accountSettingEndPoint(params) {
    const { data } = params;
    return api.update(URL.ACCOUNT_SETTING_ACCOUNT, data);
}
function getAccountantProfileApi(): any {
    return api.get(URL.GET_ACCOUNTANT_PROFILE);
}

function mobileNumberVerifyUpdateEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.MOBILE_NUMBER_VERIFY, data);
}

function mobileNumberVerifyOtpSendEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.MOBILE_NUMBER_VERIFY_OTP_SEND, data);
}

export {
    accountDetailEndPoint,
    accountSettingEndPoint,
    getAccountantProfileApi,
    mobileNumberVerifyUpdateEndPoint,
    mobileNumberVerifyOtpSendEndPoint
};

import { AccountSignupActionTypes } from './constants';

const SIGNUP_ACCOUNT_INITIAL_STATE = {
    signupAccount: [],
    loading: false,
};
const SKILL_GET_INITIAL_STATE = {
    getSkill: [],
    loading: false,
};

const INITIAL_STATE = {
    data: [],
    loading: false,
};


const getSkillReducer = (
    state = SKILL_GET_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AccountSignupActionTypes.GET_SKILL_LOADING:
            return {
                getSkill: state.getSkill,
                loading: true,
            };
        case AccountSignupActionTypes.GET_SKILL_SUCCESS:
            return {
                getSkill: action.payload,
                loading: false,
            };
        case AccountSignupActionTypes.GET_SKILL_ERROR:
            return {
                getSkill: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const getItaaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case AccountSignupActionTypes.GET_LOADING_ITAA:
            return {
                data: {},
                loading: true,
            };

        case AccountSignupActionTypes.GET_SUCCESS_ITAA:
            return {
                data: action.payload,
                loading: false,
            };
        case AccountSignupActionTypes.GET_RESET_ITAA:
            return {
                data: state,
                loading: false,
            };
        case AccountSignupActionTypes.GET_ERROR_ITAA:
            return {
                data: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const getIndustoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AccountSignupActionTypes.GET_LOADING_INDUSTORY:
            return {
                data: {},
                loading: true,
            };
        case AccountSignupActionTypes.GET_SUCCESS_INDUSTORY:
            return {
                data: action.payload,
                loading: false,
            };
        case AccountSignupActionTypes.GET_ERROR_INDUSTORY:
            return {
                data: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const signupAccountReducer = (
    state = SIGNUP_ACCOUNT_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case AccountSignupActionTypes.SIGNUP_ACCOUNTANT_LOADING:
            return {
                BusinessEmailActionTypes: state.BusinessEmailActionTypes,
                loading: true,
            };
        case AccountSignupActionTypes.SIGNUP_ACCOUNTANT_SUCCESS:
            return {
                BusinessEmailActionTypes: action.payload,
                loading: false,
            };
        case AccountSignupActionTypes.SIGNUP_ACCOUNTANT_ERROR:
            return {
                BusinessEmailActionTypes: action.payload,
                loading: false,
            };
        case AccountSignupActionTypes.SIGNUP_ACCOUNTANT_RESET:
            return SIGNUP_ACCOUNT_INITIAL_STATE;
        default:
            return state;
    }
};

const getLanguagesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AccountSignupActionTypes.GET_LANGUAGES_LOADING:
            return {
                data: INITIAL_STATE.data,
                loading: true,
            };
        case AccountSignupActionTypes.GET_LANGUAGES_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case AccountSignupActionTypes.GET_LANGUAGES_ERROR:
            return {
                data: [],
                loading: false,
                message: action?.payload,
            };
        default:
            return { ...state };
    }
};

export {
    signupAccountReducer,
    getSkillReducer,
    getItaaReducer,
    getIndustoryReducer,
    getLanguagesReducer
}
